import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Layout from '../../components/layout'
const AlbumPage = ({ data }) => {
  // const image = getImage(data.mdx.frontmatter.hero_image)
  return (
    <Layout pageTitle={data.mdx.frontmatter.title}>
      <p className='text-gray-400 text-sm'>{data.mdx.frontmatter.date}</p>
      {/* <div className='mt-6 max-w-md border-2 border-purple-500'>
      <GatsbyImage 
      image={image}
      alt={data.mdx.frontmatter.hero_image_alt} />
      </div> */}
      <div className='mt-6'>
      <MDXRenderer>
        {data.mdx.body}
      </MDXRenderer>
      </div>
    </Layout>
  )
}
export const query = graphql`
query ($id: String) {
  mdx(id: {eq: $id}, frontmatter: {hero_image: {}}) {
    frontmatter {
      title
      date(formatString: "MMMM D, YYYY")
      hero_image {
        id
        childImageSharp {
          gatsbyImageData
        }
      }
      hero_image_alt
    }
    body
  }
}
`
export default AlbumPage